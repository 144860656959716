import { graphql } from 'gatsby';
import * as React from 'react';

import { ContentSection } from '@digigear/elements';

import Layout from '../components/Layout';

const Template = ({ data }) => {
  const { markdownRemark } = data;
  return (
    <Layout>
      <ContentSection narrow>
        <h1>{markdownRemark.frontmatter.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: markdownRemark.html }} />
      </ContentSection>
    </Layout>
  );
};

export default Template;

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        slug
        title
      }
    }
  }
`;
